<template>
    <div class="page pagebrw">
        <el-row style="margin-bottom: 10px;" class="flexStart flex_1 ">
            <el-input v-model="queryForm_paifa.title" size="small" clearable style="width: 200px!important;"
                      placeholder="标题关键词">
                <el-button slot="append" size="small" icon="el-icon-search" @click="getDstrList"></el-button>
            </el-input>
        </el-row>
        <el-table
            :data="dstrList" height="calc( 100% - 126px)"
            @selection-change="handleSelectionChange"
            style="width: 100%;" stripe border size="small">
            <el-table-column label="操作" width="80" fixed="right">
                <template slot-scope="scope">


                    <el-tag v-if="scope.row.status==0" size="mini" style="cursor: pointer;margin-left: 10px;"
                            @click="dialogVisible2=true">签收</el-tag>

                </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="55"></el-table-column>
            <el-table-column prop="status" label="状态" width="130">
                <template slot-scope="scope">
					<span class="st4" v-if="scope.row.usetype==1" style="margin-right: 10px;">派发</span>
					<span class="st5" v-if="scope.row.usetype==2" style="margin-right: 10px;">申领</span>
					<span class="st0" v-if="scope.row.sp_status==0&& scope.row.status==0">待审核</span>
					<span class="st1" v-if="scope.row.sp_status==1&&scope.row.status==1">待签收</span>
					<span class="st2" v-if="scope.row.sp_status==1&&scope.row.status==2">已签收</span>
					<span class="st3" v-if="scope.row.sp_status==2">审批拒绝</span>


                </template>
            </el-table-column>
            <el-table-column prop="title" label="标题" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="false" prop="sp_status" label="审批状态"></el-table-column>
            <el-table-column prop="sq_date" label="领用日期" width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.sq_date?scope.row.sq_date.substr(0,10):'-'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="zc_desc" label="描述" :show-overflow-tooltip="true"></el-table-column>
        </el-table>
        <Pagination :pagination="page" @paginate="paginate"></Pagination>
        <!--        派发签收单-->
        <el-dialog :title="title_signfor" :append-to-body="true" :visible.sync="dialogVisible_signFor" width="1000px"
                   custom-class="width_800 cus_dialog">
            <el-form :model="EditItem" ref="EditItem" label-width="80px">
                <div v-if="title_signfor != '详情'" class="titleBar flexStart">
                    <div class="div flexStart">领用信息</div>
                </div>
                <el-row class="signforDetail-box">
                    <el-form-item label="标题:" style="margin-bottom: 0!important;">
                        {{EditItem.title}}
                    </el-form-item>
                    <el-form-item label="领用人:" v-if="EditItem.usetype==2">
                        {{EditItem.name}}
                    </el-form-item>
                    <el-form-item label="领用日期:">
                        {{EditItem.sq_date}}
                    </el-form-item>
                    <el-form-item label="物品描述:">
                        {{EditItem.zc_desc}}
                    </el-form-item>
                    <el-form-item label="申领说明:">
                        {{EditItem.remark}}
                    </el-form-item>
                    <el-form-item label="附件:">
                        <div class="updimage" v-if="EditItem.rowfiles&&EditItem.rowfiles.length>0"
                             v-for="(f,idx) in EditItem.rowfiles" :style="{backgroundImage:'url('+f.src+')'}">
                            <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
                        </div>
                        <div v-else>-</div>
                    </el-form-item>
					
					<el-form-item label="签收签名:" v-if="EditItem.handsign">
					   <div style="display: inline-block; vertical-align: top; width: 200px;">
					   	<img :src="EditItem.handsign" style="width: 100%;" />
						<div>{{EditItem.udate}}</div>
					   </div>
					</el-form-item>
					
					<el-form-item label="审批流程:" v-if="EditItem.splist&&EditItem.splist.length>0">
					    <div v-for="(spuser,idx) in EditItem.splist" :key="idx" style="border-bottom: 1px solid #eee;">
							<div style="display: inline-block; vertical-align: top; width: 150px;">
								<div>
									{{spuser.name}}
								</div>
								<div style="font-size: 12px; color: #888;">
									{{spuser.sptime}}
								</div>
							</div>
							<div style="display: inline-block; vertical-align: top; width: 200px;">
								<img :src="spuser.signimg" style="width: 100%;" />
							</div>
						</div>
					</el-form-item>
                </el-row>
				
                <div v-if="title_signfor != '详情'" class="titleBar flexStart">
                    <div class="div flexStart">签收</div>
                </div>
                <el-row v-if="title_signfor != '详情'">
                    <el-form-item label="手写签名:">
                        <el-upload v-if="!EditItem.handsign"
                                   action="" :http-request="uploadOss"
                                   :on-success="uploadCover"
                                   :show-file-list="false" :file-list="files" name="image">
                            <el-button size="mini" plain icon="el-icon-upload2" type="primary"
                                       style="margin-left: 10px;">点击上传
                            </el-button>
                        </el-upload>
                        <div v-if="EditItem.handsign" class="flexStart">
                            <img :src="EditItem.handsign" class="avatar" style="display:inline-block;width: 144px;">
                            <el-tag @click="removeImg" type="danger" size="mini" style="margin-left: 20px;"><i
                                class="el-icon-remove"></i></el-tag>
                        </div>
                    </el-form-item>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button v-if="title_signfor != '详情'" type="primary" @click="saveSignFor">确认签收</el-button>
                <el-button v-else type="primary" @click="dialogVisible_signFor=false">关闭</el-button>
            </div>
        </el-dialog>
        <!--        选择派发记录-->
        <el-dialog title="选择派发单" :append-to-body="true" :visible.sync="dialogVisible_paifaList" width="800px"
                   custom-class="width_800 cus_dialog">
            <div>
                <el-row style="margin-bottom: 10px;">
                    <el-input v-model="queryForm_paifa.title" size="small" clearable style="width: 200px!important;"
                              placeholder="标题关键词">
                        <el-button slot="append" size="small" icon="el-icon-search" @click="getDstrList"></el-button>
                    </el-input>
                </el-row>
                <el-table
                    :data="dstrList" height="calc( 100% - 126px)"
                    style="width: 100%;" stripe border size="small">
                    <el-table-column label="操作" min-width="50">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="choosePaifa(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="status" label="签收状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status==0" class="st2">未签收</span>
                            <span v-if="scope.row.status==1" class="st0">已签收</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题" min-width="120">
                        <template slot-scope="scope">
                            <div @click="showDetails(scope.row)"
                                 style="color: #409EFF;cursor: pointer;">{{scope.row.title}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sp_status" label="审批状态"></el-table-column>
                    <el-table-column prop="sq_date" label="领用日期" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="zc_where" label="领用后位置" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <Pagination :pagination="page" @paginate="paginate"></Pagination>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="dialogVisible_paifaList=false">关闭</el-button>
            </div>
        </el-dialog>
		
		<el-dialog title="请在手机端操作" :visible.sync="dialogVisible2" width="200px" style="height: 550px;" top="30vh"
		 custom-class="width_800 cus_dialog">
			<img :src="'./static/images/zichan.png'" style="width: 100%;" />
		</el-dialog>
        
        <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>
    </div>
</template>

<script>
	import Pagination from '../../com/pagination.vue'
	import Deptuser from '../../com/deptuser.vue'

	export default {
		components: {
			Pagination,
			Deptuser,
		},
		data () {
			return {
				dialogVisible2:false,
				DataList: [],
				multipleSelection: [],
				queryForm: {},
				queryForm_paifa: {},
				page: {
					current_page: 1,
					pre_page: 20,
					count: 0
				},
				page_zcList: {
					current_page: 1,
					pre_page: 20,
					count: 0
				},
				EditItem: {},
				disabled: false,
				typeList: [],
				areaList: [],
				optionProps: {value: 'id', label: 'storename', emitPath: false, checkStrictly: true},
				optionProps1: {value: 'id', label: 'cname', emitPath: false},
				files: [],
				userinfo: {},
				temp_list: [],//todo 未用备用
				showUserDialog: false,
				dialogVisible_distribute: false,
				dialogVisible_withdraw: false,
				dialogVisible_zcList: false,
				dialogVisible_paifaList: false,
				chsZCList: [],
				dstrList: [],
				dialogVisible_signFor: false,
				title_signfor:'签收单',
				drawer: false,
				curDate: '',
				chPaifaList: [],
				list_url: '/api/zc_paifa_list',
				tabTitle: '派发',
			}
		},
		mounted () {
			if (this.$route.query && this.$route.query.type == 2) {
				this.list_url = '/api/zc_my_paifa_list'
				this.tabTitle = '我的派发'
			} else {
				this.list_url = '/api/zc_hc_formlist'
				this.tabTitle = '派发'
			}
			this.get_zcList();
			this.getType();
			this.getArea();
			this.getDstrList();
			this.getCurDate();
			let _this = this
			if (localStorage.getItem("user")) {
				_this.userinfo = JSON.parse(localStorage.getItem("user"))
				// console.log(_this.userinfo)
			}
		},
		methods: {
			showSignFor (row) {
                this.showDetails(row)
				this.dialogVisible_signFor = true
				this.title_signfor = '签收单'
			},
			saveSignFor () {
				let _this = this
				let params = {}
				if ( !_this.EditItem.handsign) {
					this.$message.warning('请上传手写签名')
					return
				}
				_this.$http.post('/api/zc_hc_sl_qs', {
					id: _this.EditItem.id,
					handsign: _this.EditItem.handsign
				}).then(res => {
					if (res.data.code == 200) {
						_this.dialogVisible_signFor = false;
						_this.$message.success('保存成功');
						_this.getDstrList()
					} else {
						_this.$message.error(res.data.msg)
					}

				})
			},
			chooseZC (row) {
				console.log(row, this.chsZCList.length)

				let _this = this
				if (_this.chsZCList.length == 0) {
					_this.chsZCList.push(row)
				} else {
					if (_this.dep_Arr1(_this.chsZCList, row.id)) {
						_this.chsZCList.push(row)
					} else {
						_this.$message.warning('选择重复！')
					}
				}
			},
			dep_Arr1 (arr, id) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.id == id) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},
			removeZC (row) {
				let _this = this
				_this.chsZCList.map((z, i) => {
					if (z.id == row.id) {
						_this.chsZCList.splice(i, 1)
					}
				})
			},
			//派发签收
			choosePaifa (row) {
				let _this = this
				if (_this.chPaifaList.length == 0) {
					_this.chPaifaList.push(row)
				} else {
					if (_this.dep_Arr1(_this.chPaifaList, row.id)) {
						_this.chPaifaList.push(row)
					} else {
						_this.$message.warning('选择重复！')
					}
				}
			},
			removeZC (row) {
				let _this = this
				_this.chPaifaList.map((z, i) => {
					if (z.id == row.id) {
						_this.chPaifaList.splice(i, 1)
					}
				})
			},
			//派发单
			saveDistribute () {
				let _this = this
				let params = {}
				params = {..._this.EditItem}
				params.usetype = 2
				let arr = []
				params.files = JSON.stringify(arr)
				if ( !params.borrower_name) {
					_this.$message.warning('请选择领用人')
					return;
				}
				let ids = [], titles = []
				if (_this.chsZCList.length > 0) {
					_this.chsZCList.map(s => {
						ids.push(s.id)
						titles.push(s.title)
					})
					params.zc_id = ids.join(',')
					params.title = `${titles.join(',')}派发给${params.borrower_name}`
					_this.$http.post('/api/zc_paifa', params).then(res => {
						_this.dialogVisible_distribute = false;
						_this.$message({
							type: 'success',
							message: '保存成功'
						});
						_this.getDstrList()
					})
				} else {
					_this.$message.warning('请选择资产！')
					return
				}

			},
			//申请归还
			apply_return (row) {
				let _this = this
				this.$confirm('是否确定归还?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_back_apply", {id: row.id}).then(res => {
						this.$message.success("success");
						_this.getDstrList()
					})
				})
			},
			getDstrList () {
				let _this = this
				let params = {}
				params.page = this.page.current_page
				params.pagesize = this.page.pre_page
				if (_this.queryForm_paifa != {}) {
					for (let key in _this.queryForm_paifa) {
						params.ktype = key
						params.keyword = _this.queryForm_paifa[key]
					}
				}
				this.$http.post(_this.list_url, params).then(res => {
					this.dstrList = res.data.data;
					this.page = res.data.page
				})
			},
			get_zcList () {
				let _this = this
				let params = {}, arr = [{key: "status", value: "0"}]
				params.page = this.page.current_page
				params.pagesize = this.page.pre_page
				// params.ktype = "status"
				// params.keyword = "0"
				if (_this.queryForm != {}) {
					for (let key in _this.queryForm) {
						if (_this.queryForm[key]) {
							arr.push({key: key, value: _this.queryForm[key]})
						}
					}
					params.search = arr
				}
				this.$http.post("/api/zc_list", params).then(res => {
					if (res.data.code != 500) {
						this.DataList = res.data.data
						this.page_zcList = res.data.page
					} else {
						this.DataList = []
						this.page_zcList.current_page = 1
					}
				})
			},
			onSearch () {
				this.page_zcList.current_page = 1
				this.get_zcList()
			},
			onReset () {
				this.queryForm = {}
				this.page_zcList.current_page = 1
				this.get_zcList()
			},
			add () {
				this.dialogVisible_distribute = true
				this.disabled = false
				this.EditItem = {
					sq_date: this.curDate
				}
				this.files = []
				this.chsZCList = []
			},

			dep_Arr (arr) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.status != 0) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},

			uploadCover (e) {
				let _this = this
				console.log(e)
				this.EditItem.handsign = e.src
				_this.EditItem = {..._this.EditItem}
			},
			removeImg () {
				let _this = this
				this.EditItem.handsign = ''
				_this.EditItem = {..._this.EditItem}
			},
			getType () {
				let _this = this
				this.$http.post("/api/zc_type",{type:'hc'}).then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.typeList = result
					})
				})
			},
			getArea () {
				let _this = this
				this.$http.post("/api/zc_store").then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.areaList = result
					})
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup (treeData) {
				return new Promise(resolve => {
					function traversal (data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},

			handleSelectionChange (val) {
				this.multipleSelection = val;
				console.log(val)
			},
			handleSelectionChange1 (val) {
				console.log(val)
			},
			showDetails (row) {
				console.log(row)
				let _this = this
                let arr = []
				this.dialogVisible_signFor = true
                this.title_signfor = '详情'
				if(row.title){
                    let idx = row.title.indexOf('申请领用')
					row.name = row.title.substr(0,idx)
				 }
				if(row.files){
					arr = JSON.parse(row.files)
					row.rowfiles = arr
				}
				row.splist=[];
				if(row.process_json){
					try{
						row.process_json = JSON.parse(row.process_json)
						for(let item of row.process_json){
							if(item.next){
								for(let n of item.next){
									if(n.name){
										row.splist.push(n)
									}
								}
							}
						}
					}catch(e){
						//TODO handle the exception
					}
				}
				
				
				_this.EditItem = {...row}
				
				
				
			},
			paginate (val) {
				this.page.current_page = val
				this.getDstrList();
			},
			paginate_zcList (val) {
				this.page_zcList.current_page = val
				this.get_zcList();
			},
			handleCascaderChange (e) {
				console.log(e)
			},
			handleCascaderChange1 (e) {
				this.EditItem.zc_typeid = e
				let a = this.$refs['myCascader1'].getCheckedNodes()[0].data
				this.EditItem.ename = a.ename
			},
			getUser (e) {
				console.log(e)
				let _this = this
				if (e && e.length > 0) {
					_this.EditItem.target_uid = e[0].union_id
					_this.EditItem.borrower_name = e[0].name
					_this.showUserDialog = false
				}
			},
			closeUser () {
				this.showUserDialog = false
			},
			getCurDate () {
				let d = new Date();
				let year = d.getFullYear(); //获取完整的年份(4位)
				let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let date = d.getDate(); //获取当前日(1-31)
				d.getDay(); //获取当前星期X(0-6,0代表星期天)
				d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
				d.getHours(); //获取当前小时数(0-23)
				d.getMinutes(); //获取当前分钟数(0-59)
				d.getSeconds(); //获取当前秒数(0-59)
				d.getMilliseconds(); //获取当前毫秒数(0-999)
				d.toLocaleDateString(); //获取当前日期
				let mytime = d.toLocaleTimeString(); //获取当前时间
				d.toLocaleString(); //获取日期与时间
				this.curDate = `${year}-${month}-${date}`
			},
			handleCascaderChange2 (e) {
				// console.log(e)
				if (e) {
					let _this = this
					_this.EditItem.zc_whereid = e
					let a = _this.$refs['myCascader2'].getCheckedNodes()[0].data
					let allCates = _this.getFathersById(e, _this.areaList, 'id', 'children', 'storename')
					allCates.pop();
					let ss = allCates.length > 0 ? `${allCates.join('/')}/${a.storename}` : a.storename
					_this.EditItem.zc_where = ss
					console.log(_this.EditItem.zc_where)
					_this.EditItem = {..._this.EditItem}
				}
			},
			/**
			 * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
			 * id:     匹配的值
			 * data: 匹配的数组数据
			 * prop: 匹配的字段名
			 * children: 子集label
			 * cname: 输出数组需要的字段名
			 */
			getFathersById (id, data, prop, children, cname) {
				var arrRes = [], arrText = []
				const rev = (data, nodeId) => {
					for (var i = 0, length = data.length; i < length; i ++) {
						const node = data[i]
						if (node[prop] == nodeId) {
							arrRes.unshift(node[prop])
							arrText.unshift(node[prop])
							return true
						} else {
							if (node[children] && node[children].length) {
								if (rev(node[children], nodeId)) {
									arrRes.unshift(node[prop])
									arrText.unshift(node[cname])
									return true
								}
							}
						}
					}
					return false
				}
				rev(data, id)
				return arrText  //返回中文集合
			},

		}
	}
</script>

<style lang="less" type="text/scss">
    .updimage{
        width: 100px; height: 100px; display: inline-block; vertical-align: top; margin: 0 10px 10px 0; border: 1px dotted #ccc;
        background-size: 100% auto;
        background-position: center;
        overflow: hidden;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .signforDetail-box{
        border: 1px solid #E4E7ED;
        margin: 20px 0 20px 30px;
        .el-form-item {
            margin-bottom: 0px;
            border-top: 1px solid #EBEEF5;
        }
        .el-form-item:first-of-type {
            border-top: none;
        }
    }
    .pagebrw {
        .el-tabs__content {
            height: calc(100% - 40px);
        }
        
        .el-input, .el-select, .el-cascader {
            width: 100% !important;
        }
        
        .el-dropdown {
            vertical-align: top;
        }
        
        .el-dropdown + .el-dropdown {
            margin-left: 15px;
        }
        
        .el-icon-arrow-down {
            font-size: 12px;
        }
        
        .cus_dropdown {
            .el-button--primary {
                color: #409EFF !important;
                background-color: #Ecf5ff !important;
                border-color: #b3d8ff !important;
            }
        }
        
        .el-divider {
            margin-top: 0;
        }
        
        .el-drawer__wrapper {
            top: 15vh;
            height: 76.2vh;
        }
        
        .drawer_box {
            padding: 10px;
            
            .el-form-item {
                margin-bottom: 0px;
            }
        }
    }
    
   

</style>
